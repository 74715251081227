/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn-cancel {
        @apply text-black border border-light-green px-3 py-2 text-sm rounded-md font-semibold hover:bg-gray-200;
    }

    .sm-modal {
        @apply overflow-scroll lg:w-[700px] lg:min-h-[200px] lg:h-fit lg:max-h-[700px] md:w-[600px] md:min-h-[200px] md:h-fit md:max-h-[700px] w-screen min-h-[200px];
    }

    .sq-modal {
        @apply overflow-scroll lg:w-[300px] lg:min-h-[300px] lg:h-fit lg:max-h-[700px] md:w-[300px] md:min-h-[200px] md:h-fit md:max-h-[700px] w-screen min-h-[200px];
    }

    .btn-primary {
        @apply bg-light-green text-white font-bold py-2 px-4 rounded inline-flex items-center shadow-sm hover:bg-dark-green disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-light-green;
    }

    .label {
        @apply block text-sm font-medium text-gray-600 leading-6;
    }

    .label-primary {
        @apply block text-sm font-bold text-dark-green leading-6;
    }

    .title {
        @apply block font-bold text-dark-green leading-6 mt-4 mb-4;
    }

    .label-primary-white {
        @apply block text-sm font-bold text-white leading-6;
    }

    .select {
        @apply w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-dark-green focus:ring-2 focus:ring-inset focus:ring-dark-green sm:text-sm sm:leading-6;
    }

    .textarea {
        @apply block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-dark-green sm:text-sm sm:leading-6;
    }

    .input-text {
        @apply block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-light-green sm:text-sm sm:leading-6;
    }

    .btn-icon {
        @apply w-6 h-6 hover:text-dark-green hover:bg-gray-200 rounded-full
    }

    .form-border {
        @apply space-y-12 bg-white rounded-lg shadow-lg p-5 border border-dark-green
    }

    .resizable-mat-dialog-panel {
        resize: both;
        overflow: auto;
        max-width: unset !important;
        max-height: unset !important;
        position: absolute !important;
    }
}

@font-face {
    font-family: 'KGBrokenVesselsSketch';
    src: url('assets/fonts/KGBrokenVesselsSketch.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

html,
body {
    height: 100%;
    --mat-option-selected-state-label-text-color: #E76F51;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.error .label {
    color: red;
    font-weight: 600;
}

.error div input,
.error div textarea,
.error div select {
    color: red;
    border: 1px solid red;
}

.mat-mdc-autocomplete-panel {
    background-color: #274653 !important;
    border-radius: 0.5rem !important;
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5rem;
}

::-webkit-scrollbar-thumb {
    background: #274653;
    border-radius: 5rem;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.dropdown-menu::-webkit-scrollbar {
    width: 0;
}

.scroll-item:hover + .dropdown-menu::-webkit-scrollbar,
.dropdown-menu:hover::-webkit-scrollbar {
  width: 4px;
}